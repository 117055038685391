export const fontSizes = [0.75, 0.875, 1, 1.25, 1.5, 2, 3, 4];

export const colors = {
  alabaster: "#f9f9f9",
  f5: "#f5f5f5",
  darkBlue: "#003d60",
  blue: "#006e99", //"#007fb0",
  grey: "#3c4042",
  midGrey: "#666",
  lightGrey: "#999",
  green: "#cf0",
  border: "#e0e6e9",
  yellow: "#FCF00D"
};

const theme = {
  breakpoints: {
    mobile: 0, // targeting all devices
    tablet: 737, // targeting devices that are larger than the iPhone 6 Plus (which is 736px in landscape mode)
    desktop: 1025, // targeting devices that are larger than the iPad (which is 1024px in landscape mode)
    widescreen: 1280
  },
  colors,
  fontSizes,
  headings: {
    h1: fontSizes[7],
    h2: fontSizes[6],
    h3: fontSizes[5],
    h4: fontSizes[4],
    h5: fontSizes[3],
    h6: fontSizes[2]
  },
  ease: "cubic-bezier(0.455, 0.030, 0.515, 0.955)"
};

export default theme;
