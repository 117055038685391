import React from "react";
import styled from "styled-components";
import { Padding } from "styled-components-spacing";
import Container from "./Container";

const El = styled.section`
  width: 100%;
`;

const Section = props => (
  <El {...props}>
    <Padding
      vertical={{ mobile: 4 }}
      all={{ mobile: 3, tablet: 4, desktop: 5 }}
    >
      <Container>{props.children}</Container>
    </Padding>
  </El>
);

export default Section;
