import React from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";
import { Margin } from "styled-components-spacing";
import Heading from "./Heading";
import Section from "./Section";
import Button from "./Button";

const Wrapper = styled(Section)`
  text-align: center;
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const CTAFooter = props => (
  <Wrapper {...props}>
    <Margin bottom={3}>
      <Heading size={4} style={{ margin: "auto" }}>
        We look forward to working with you on your next project.
      </Heading>
    </Margin>
    <Button as={GatsbyLink} to="/contact">
      Get in touch
    </Button>
  </Wrapper>
);

export default CTAFooter;
