import { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

// These values are the minimum and maximum viewport sizes to apply the font scaling
const minWidth = 400;
const maxWidth = 1000;

// These values represent the range of font-size to apply
// These values effect the base font-size, headings and other elements will scale proportionally
const minFont = 16;
const maxFont = 21;

// export default css`
//   html {
//     font-size: ${minFont}px;
//     line-height: 1.5;

//     ${breakpoint('tablet')`
//       font-size: 18px;
//     `}

//     ${breakpoint('desktop')`
//       font-size: 21px;
//     `}
//   }
// `;

export default css`
  html {
    font-size: ${minFont}px;
    line-height: 1.5;
  }

  @media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px) {
    html {
      font-size: calc(
        ${minFont}px + (${maxFont} - ${minFont}) *
          ((100vw - ${minWidth}px) / (${maxWidth} - ${minWidth}))
      );
    }
  }

  @media (min-width: ${maxWidth}px) {
    html {
      font-size: ${maxFont}px;
    }
  }
`;
