import React from "react";
import styled, { css } from "styled-components";

const Heading = ({ component, size, children, ...rest }) => {
  const Component = component ? component : `h${size}`;
  return <Component {...rest}>{children}</Component>;
};

const StyledHeading = styled(Heading)`
  ${({ size }) => size === 1 && h1Styles}
  ${({ size }) => size === 2 && h2Styles}
  ${({ size }) => size === 3 && h3Styles}
  ${({ size }) => size === 4 && h4Styles}
  ${({ size }) => size === 5 && h5Styles}
  ${({ size }) => size === 6 && h6Styles}
`;

export const headingStyles = css`
  font-weight: 400;
  line-height: 1;
  margin: 0;
`;

export const h1Styles = css`
  ${headingStyles};
  font-size: ${props =>
    props.theme && props.theme.headings && props.theme.headings.h1}rem;
`;
export const h2Styles = css`
  ${headingStyles};
  font-size: ${props =>
    props.theme && props.theme.headings && props.theme.headings.h2}rem;
`;
export const h3Styles = css`
  ${headingStyles};
  font-size: ${props =>
    props.theme && props.theme.headings && props.theme.headings.h3}rem;
`;
export const h4Styles = css`
  ${headingStyles};
  font-size: ${props =>
    props.theme && props.theme.headings && props.theme.headings.h4}rem;
`;
export const h5Styles = css`
  ${headingStyles};
  font-size: ${props =>
    props.theme && props.theme.headings && props.theme.headings.h5}rem;
`;
export const h6Styles = css`
  ${headingStyles};
  font-size: ${props =>
    props.theme && props.theme.headings && props.theme.headings.h6}rem;
`;

export default StyledHeading;
