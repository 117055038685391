import React from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Grid from "styled-components-grid";
import { p, py, px, mr } from "styled-components-spacing";

import Container from "./Container";
import Link from "./Link";
import logo from "../img/SkeltonLogo.svg";

const NavEl = styled.nav`
  ${px({ mobile: 3, tablet: 4, desktop: 5 })};
  background-color: #f9f9f9;
  box-shadow: inset 0 -1px 0 0 ${props => props.theme.colors.border};
  align-self: flex-end;

  ${breakpoint("tablet")`
    position: sticky;
    top: 0;
    z-index: 5;
    `}
`;

const LogoImg = styled.img`
  height: 3rem;
  position: relative;
  top: 6px;
`;

const NavLink = styled(Link).attrs({ activeClassName: "active" })`
  ${px(2)};
  ${py(3)};
  position: relative;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.grey};
  font-weight: 500;
  text-decoration: none;

  :not(:last-child) {
    ${mr(2)};
  }

  ::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: ${props => props.theme.colors.blue};
    transform: scaleX(0);
    transition: transform 0.2s ${props => props.theme.ease};
  }

  &.active,
  :hover,
  :focus {
    ::after {
      transform: scaleX(1);
    }
  }

  ${breakpoint("tablet")`
    ${p(2)};
  `}
`;

const LogoLink = styled(Link)`
  display: inline-block;
  ${py(2)};
`;

const Logo = () => (
  <LogoLink to="/" title="Logo">
    <LogoImg src={logo} alt="Skelton Consulting Engineers logo" />
  </LogoLink>
);

const Navbar = () => (
  <NavEl role="navigation" aria-label="main-navigation">
    <Container>
      <Grid halign="justify">
        <Grid.Unit size="min">
          <Logo />
        </Grid.Unit>
        <Grid.Unit size="min" style={{ display: "flex" }}>
          <NavLink to="/our-people">Our People</NavLink>
          <NavLink to="/projects">Projects</NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </Grid.Unit>
      </Grid>
    </Container>
  </NavEl>
);

export default Navbar;
