import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import PageContainer from "../components/PageContainer";
import Navbar from "../components/Navbar";
import {
  h1Styles,
  h2Styles,
  h3Styles,
  h4Styles,
  h5Styles,
  h6Styles
} from "../components/Heading";
import { linkStyles } from "../components/Link";
import Footer from "../components/Footer";
import CTAFooter from "../components/CTAFooter";
import theme from "../components/theme";
import fluidStyles from "../components/Fluid";

export const Reset = createGlobalStyle`

  ${fluidStyles};

  a:focus,
  input:focus,
  textarea:focus,
  select:focus,
  button:focus {
    outline: .125rem solid ${theme.colors.yellow};
    outline-offset: .125rem;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5;
    background-color: ${theme.colors.f5};
    color: ${theme.colors.grey};
  }

  a {
    ${linkStyles}
  }

  h1 { ${h1Styles} }
  h2 { ${h2Styles} }
  h3 { ${h3Styles} }
  h4 { ${h4Styles} }
  h5 { ${h5Styles} }
  h6 { ${h6Styles} }

  p {
    margin: 0 0 1rem;
  }

  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6, 
  p {
    max-width: 32em;
  }
`;

const TemplateWrapper = ({ showCta, children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <PageContainer>
          <Reset />
          <Helmet>
            <html lang="en-AU" />
            <title>{data.site.siteMetadata.title}</title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color="#007fb0"
            />
            <meta
              name="apple-mobile-web-app-title"
              content="Skelton Consulting Engineers"
            />
            <meta
              name="application-name"
              content="Skelton Consulting Engineers"
            />
            <meta name="msapplication-TileColor" content="#2d89ef" />
            <meta name="theme-color" content="#ffffff" />

            <meta property="og:type" content="business.business" />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:url" content="/" />
            <meta property="og:image" content="/og-image.jpg" />
          </Helmet>
          <Navbar />
          <div>{children}</div>
          {showCta && <CTAFooter />}
          <Footer />
        </PageContainer>
      </ThemeProvider>
    )}
  />
);

TemplateWrapper.defaultProps = {
  showCta: true
};

export default TemplateWrapper;
