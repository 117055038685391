import styled from "styled-components";
import theme from "../components/theme";

const PageContainer = styled.div`
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  background: ${theme.colors.alabaster};
  box-shadow: 0 2px 4px 0 #ddd;
`;

export default PageContainer;
