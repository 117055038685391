import styled from "styled-components";
import { py, px } from "styled-components-spacing";

const Button = styled.button`
  display: inline-block;
  ${py(2)};
  ${px(3)};
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors && theme.colors.blue};
  background-image: linear-gradient(120deg, #006e99, #003d5f);
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    color: white;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1.05) translateY(0.0625rem);
  }
`;

export default Button;
