import { Link as GatsbyLink } from "gatsby";
import styled, { css } from "styled-components";

export const linkStyles = css`
  color: ${props => props.theme.colors.blue};
  transition: color 0.3s ${props => props.theme.ease};

  &:hover {
    color: ${props => props.theme.colors.darkBlue};
    /* text-decoration: none; */
  }
`;

const Link = styled(GatsbyLink)`
  ${linkStyles};
`;

Link.displayName = "Link";

export default Link;
