import React from "react";
import styled from "styled-components";
import { mb, px, py } from "styled-components-spacing";

import Link from "../components/Link";
import logo from "../img/skelton-glyph.svg";

const Wrapper = styled.footer`
  ${py(4)};
  ${px(2)};
  text-align: center;
  border-top: 1px solid ${props => props.theme.colors.border};
`;

const Logo = styled.img`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  ${mb(2)};
`;

const Small = styled.div`
  font-size: 0.875rem;
  /* color: ${props => props.theme.colors.midGrey}; */
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Wrapper>
      <Logo src={logo} alt="Skelton Consulting Engineers logo" />
      <Small>
        &copy; {currentYear} Skelton Consulting Engineers Pty Limited
        <br />
        ACN 608 365 760 &middot; Website by{" "}
        <Link as="a" href="https://lauriejones.me">
          Laurie Jones
        </Link>
      </Small>
    </Wrapper>
  );
};

export default Footer;
